/* eslint-disable @typescript-eslint/camelcase */
import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import { RequestUpdate } from "@/domain/entities/CustomProcess";
import { QueryParamsEntities } from "@/domain/entities/MainApp";
import { UpdateStatusRequestInterface } from "../contracts/CustomProcessRequest";
import { getHubData } from "@/app/infrastructures/misc/Cookies";

export class UpdateStatusApiRequest implements UpdateStatusRequestInterface {
  payload: RequestUpdate = new RequestUpdate();
  constructor(fields?: Partial<UpdateStatusApiRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    const sttReceiptPhone = `62${this.payload.sttReceiptPhone}`;
    return JSON.stringify(
      ConvertObjectCamelToSnakeCase(
        new RequestUpdate({
          ...this.payload,
          sttReceiptPhone: sttReceiptPhone.includes("undefined") ? "" : sttReceiptPhone,
          sttPiecePerPack: Number(this.payload.sttPiecePerPack),
          sttNextCommodity: String(this.payload.sttNextCommodity),
          hubId: getHubData()?.hubId || 0,
          hubName: getHubData()?.hubName || "",
          hubDistrictCode: getHubData()?.hubDistrictCode || "",
          hubOriginCity: getHubData()?.originCity || ""
        })
      )
    );
  }
}

export class RequestListCustomProcess {
  page = 0;
  limit = 0;
  startDate = "";
  endDate = "";
  status = "";

  constructor(fields?: Partial<RequestListCustomProcess>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
