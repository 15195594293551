
import { Options, Vue, prop } from "vue-class-component";
import Estimation from "./estimation.vue";
import { CustomProcessEditAddressForm } from "@/domain/entities/CustomProcess";
import { AccountController } from "@/app/ui/controllers/AccountController";
import convertPhoneNumberV2 from "@/app/infrastructures/misc/common-library/ConvertPhoneNumberV2";
import { DistrictData } from "@/domain/entities/Location";
import { ClientPartnerOptions } from "@/domain/entities/CnManifest";
import debounce from "lodash/debounce";
import { PartnerController } from "@/app/ui/controllers/PartnerController";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { OptionsClass } from "@/domain/entities/MainApp";
import { CommodityController } from "@/app/ui/controllers/CommodityController";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";
import { formattedNPWP } from "@/app/infrastructures/misc/Utils";
import { ProductDeliveryData } from "@/domain/entities/Booking";
import { Commodity } from "@/domain/entities/Commodity";
import { FlagsFeatureShipmentC1 } from "@/feature-flags/flags-shipment-c1";

class Props {
  defaultStt = prop<CustomProcessEditAddressForm>({
    default: new CustomProcessEditAddressForm()
  });
  posField = prop<boolean>({
    default: false
  });
}

@Options({
  emits: ["close", "onSubmit"],
  components: {
    Estimation
  }
})
export default class extends Vue.with(Props) {
  mounted() {
    this.form = JSON.parse(JSON.stringify(this.defaultStt));
    this.estimationKey++;
    this.fetchDetailPartner();
  }

  get accountData() {
    return AccountController.accountData;
  }

  closeModal() {
    this.$emit("close", false);
  }

  estimationKey = 0;

  error = {
    consigneePhone: false
  };

  formatNumber(value: string) {
    const newValue = value.replace(/^62|^0|\D+/g, "");
    return newValue.startsWith("0")
      ? newValue.slice(1, newValue.length)
      : newValue;
  }

  validateConsigneePhone(value: string) {
    this.error.consigneePhone = !/^\d+$/.test(value) || value.length < 8;
  }

  formatPiecePerPack(value: string) {
    return value.replace(/\D+/g, "").replace(/\D+/g, "");
  }

  // destination
  onSelectDistrict(data: DistrictData) {
    this.form.postalCode = data.destinationZipCode;
  }
  get isErrorCod() {
    return (
      !!this.form.sttIsCod?.match(/yes/gi) &&
      !!(this.form.destinationCity as DistrictData)?.isCod?.match(/no/gi)
    );
  }

  // product type
  filterProductsDelivery = debounce((search: string) => {
    BookingController.getProductsDelivery({
      search: search,
      page: 1,
      limit: 10,
      status: "active"
    });
  }, 250);
  get productsDelivery(): ProductDeliveryData[] {
    return BookingController.productsDelivery.data;
  }
  get isLoadingProduct() {
    return BookingController.loadingProductsDelivery;
  }
  onSelectProduct(data: ProductDeliveryData) {
    this.form.product = data;
    this.form.postalCode = "";
    this.form.destinationCity = "";
    this.form.sttIsInterpack = !!data.name.match(/^INTERPACK$/);
  }

  // comodities
  getCommodityList() {
    CommodityController.getCommodityList(new RequestListCommodity());
  }
  get commodities(): Commodity[] {
    return CommodityController.commodities.data;
  }
  fetchCommodityList = debounce((search: string) => {
    CommodityController.getCommodityList(
      new RequestListCommodity({
        search: search
      })
    );
  }, 250);
  onSelectCommodity(data: Commodity) {
    this.form.commodity = data;
  }

  // status good
  isOpenSelectStatus = false;
  listGoodsStatus = [
    new OptionsClass({ name: "Ecommerce", value: "ecommerce" }),
    new OptionsClass({ name: "Produk lokal / Batam", value: "batam" }),
    new OptionsClass({ name: "Eks luar Batam / transit", value: "transit" }),
    new OptionsClass({ name: "Salah kirim / return", value: "return" }),
    new OptionsClass({ name: "Personal effect", value: "personal-effect" })
  ];
  onSelectStatus(data: OptionsClass) {
    this.form.goodsStatus = data;
  }

  // tax number
  formattedNPWP(value: string) {
    return formattedNPWP(value.replace(/\D+/g, ""));
  }

  get paramsExcludeCountry(): string {
    return this.form.sttIsInterpack ? "ID" : "";
  }
  get isForwardArea(): boolean {
    return (
      this.defaultStt.sttIsDo &&
      !!(
        (this.form.destinationCity as DistrictData).type ||
        String(this.form.destinationCity)
      ).match(/^forward-area$/)
    );
  }
  get errorDistrictDestination(): string {
    if (this.isErrorCod) {
      return "Kecamatan & Kota Tujuan yang Anda masukkan belum menyediakan layanan COD, silakan ubah data";
    } else if (this.isForwardArea) {
      return "Kecamatan & Kota Tujuan yang Anda masukkan belum menyediakan layanan DO, silakan ubah data";
    }
    return "";
  }
  get isVisiblePostalCode(): boolean {
    return (
      this.form.sttIsInterpack &&
      !!(
        (this.form.product as ProductDeliveryData)?.name ||
        String(this.form.product)
      ).match(/^INTERPACK$/)
    );
  }

  get disabledSubmit() {
    return (
      this.error.consigneePhone ||
      !this.form.senderName ||
      !this.form.consigneeName ||
      !this.form.senderAddress ||
      !this.form.consigneeAddress ||
      !this.form.senderPhone ||
      !this.form.consigneePhone ||
      !this.form.originCity ||
      !this.form.destinationCity ||
      (this.form.sttIsInterpack &&
        (!this.form.postalCode || this.form.postalCode.length > 6)) ||
      !this.form.addressType ||
      this.isErrorCod ||
      this.isForwardArea ||
      (!this.posField && (!this.form.product || !this.form.commodity)) ||
      (this.form.taxNumber && this.form.taxNumber.length < 20) ||
      (this.form.isFtz &&
        (!String(this.form.sttPiecePerPack).match(/^\d+$/) ||
          !String(this.form.sttNextCommodity).length)) ||
      (this.posField && !this.selectedPartnerPos)
    );
  }

  form: CustomProcessEditAddressForm = new CustomProcessEditAddressForm();

  get dataSender() {
    const isReroute = this.form.statusStt === "REROUTE";
    return {
      name: isReroute
        ? this.form.senderName
        : this.accountData.account_type_detail.company_name,
      address: isReroute
        ? this.form.senderAddress
        : this.accountData.account_type_detail.address,
      phoneNumber: convertPhoneNumberV2(
        isReroute
          ? this.form.senderPhone
          : this.accountData.account_type_detail.partnerLocation.partner
              .partner_phone_number
      ),
      district: this.accountData.account_location.district_name,
    };
  }

  onSubmitEditAddress() {
    this.closeModal();
    this.$emit("onSubmit", this.form);
  }

  get paramsType() {
    return this.form.sttIsDo ? "within-city,forward-area" : "";
  }

  get parentId() {
    return AccountController.accountData.account_type_detail.id;
  }

  isLoadingPartners = true;
  selectedPartnerPos: any = "";
  partnersData: ClientPartnerOptions[] = [];
  filterPartners = debounce(async (search: string) => {
    this.isLoadingPartners = true;
    const resp = await PartnerController.fetchPartnerApi({
      search: search,
      page: 1,
      limit: 10,
      type: "pos",
      parentId: this.parentId,
      status: "not-banned"
    });
    this.partnersData = resp.filter((key: ClientPartnerOptions) =>
      key.contractStatus.match(/^active|^nearly-expired/gi)
    );
    this.isLoadingPartners = false;
  }, 250);
  onChangePartner = debounce((value: ClientPartnerOptions) => {
    this.selectedPartnerPos = value;
    this.form.sttBookedBy = value.id;
  });

  // check is user has pos reverse journey
  get isUserHasPartnerPos() {
    return !!this.accountData.account_type_detail.partnerIdSttReturn;
  }

  async fetchDetailPartner() {
    if (this.isUserHasPartnerPos) {
      const partnerDetail = await PartnerController.fetchPartnerDetail({
        id: this.parentId,
        tieringLevel: false
      });
      this.selectedPartnerPos = {
        id: partnerDetail.partnerIdSttReturn,
        name: partnerDetail.partnerNameSttReturn
      };
      this.form.sttBookedBy = partnerDetail.partnerIdSttReturn;
    }
  }

  get isCodRetail() {
    return (
      this.form.sttIsCod.toLowerCase() === "yes" &&
      this.form.statusStt === "RTS" &&
      (this.sttCodRetail || this.sttCodCaRetail)
    );
  }

  get sttCodRetail() {
    return (
      this.form.sttNumber.toLocaleLowerCase().match(/^11lp/gi) ||
      this.form.sttNumber.toLowerCase().match(/^10lp/gi)
    );
  }
  get sttCodCaRetail() {
    return this.form.shipmentId.match(/^ARA|^ARB/gi);
  }

  get productsDeliveryWithoutOnePack() {
    return this.productsDelivery.filter(
      (e: ProductDeliveryData) => e.name !== "ONEPACK"
    );
  }

  get isHiddenOnepack() {
    return this.sttCodRetail;
  }

  get shipmentC1SpecialCod() {
    return this.form.shipmentId.startsWith(FlagsFeatureShipmentC1.feature_shipment_c1.getValue()) 
      && this.form.sttIsCod.toLowerCase() === "yes" 
      && this.form.codHandling.toLowerCase() === "specialcod"
  }

  get shipmentFavoritCodDfod() {
    return this.form.shipmentId.match(/^AP|^AS/gi) && this.CodDfodValue !== "non-cod" 
  }

  get CodDfodValue() {
    return this.form.sttIsCod.toLowerCase() === "yes" && !this.form.sttIsDfod ? "cod" 
          : this.form.sttIsCod.toLowerCase() === "yes" && this.form.sttIsDfod ? "dfod" 
          : "non-cod";
  }
}
